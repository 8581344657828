<template>
  <b-container class='orders'>
    <b-row>
      <b-col class="my-5">
        <no-ssr>
          <v-server-table
            :url="url"
            :columns="columns"
            :options="options"
          />
        </no-ssr>

        <order-modal
          :show="show"
          @update:show="show = $event"
          :order="selectedOrder"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
/* import Vue from 'vue' */
import Config from '@/config'
import OrderModal from '@/components/Modal/Order'
import moment from 'moment'
import Vue from 'vue'

export default {
  name: 'orders',
  data: () => ({
    columns: ['entity_id', 'grand_total', 'status', 'created_at'],
    show: false,
    selectedOrder: {}
  }),
  computed: {
    options () {
      return {
        perPage: 2,
        uniqueKey: 'entity_id',
        responseAdapter: data => ({
          data: data.items,
          count: data.total_count
        }),
        headings: {
          entity_id: 'ID',
          grand_total: this.$t('GrandTotal'),
          status: this.$t('datatables.status'),
          created_at: this.$t('datatables.created_at')
        },
        filterByColumn: true,
        filterable: ['status'],
        listColumns: {
          status: [{
            id: 'pending',
            text: 'pending'
          },
          {
            id: 'sending',
            text: 'sending'
          }]
        },
        texts: {
          filterBy: this.$t('datatables.filterby'),
          defaultOption: this.$t('datatables.select'),
          noResults: this.$t('datatables.noResults')
        },
        templates: {
          created_at: function (h, row) {
            return moment(row.created_at).format(this.$t('dateFormat'))
          },
          grand_total: function (h, row) {
            return Vue.filter('price')(row.grand_total)
          }
        }
      }
    },
    ...mapState({
      orders: state => state.orders.items
    }),
    url () {
      let query = new URLSearchParams()
      query.append('storeViewCode', this.$store.state.ui.storeViewCode)
      query.append('token', this.$store.state.user.token)
      return process.env.VUE_APP_SERVER + Config.API.order.list + '?' + query.toString()
    }
  },
  head () {
    return {
      title: 'Orders'
    }
  },
  mounted () {
    const _this = this
    global.$EventDataTable.$on('vue-tables.loaded', data => {
      _this.$store.commit('ui/hideLoader')
    })

    global.$EventDataTable.$on('vue-tables.loading', data => {
      _this.$store.commit('ui/showLoader')
    })

    global.$EventDataTable.$on('vue-tables.error', error => {
      console.error(error)
      _this.$store.commit('ui/hideLoader')
      _this.$router.replace({ name: 'home' })
    })

    global.$EventDataTable.$on('vue-tables.row-click', rowClick => {
      _this.selectedOrder = rowClick.row
      _this.show = true
    })
  },
  destroyed () {
    global.$EventDataTable.$off('vue-tables.loaded')

    global.$EventDataTable.$off('vue-tables.loading')

    global.$EventDataTable.$off('vue-tables.error')

    global.$EventDataTable.$off('vue-tables.row-click')
  },
  components: {
    OrderModal
  }
}
</script>
